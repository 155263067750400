import React, { useEffect, useRef } from "react";

const Section2Rolunk = () => {
  const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);
  return (
    <div>
        <div class="r-lunk-section1-copy">
      <div class="rolunkcontent1">
        <div class="szoveg1">
          <div class="szoveg1jobb">
          <img 
          src="images/rolunkkep.png" 
          loading="lazy" 
          //sizes="(max-width: 767px) 300px, 100vw"
          //srcset="images/53361190_1314228155405809_3296795889724555264_n-p-500.jpg 500w, images/53361190_1314228155405809_3296795889724555264_n-p-800.jpg 800w, images/53361190_1314228155405809_3296795889724555264_n.jpg 1080w" 
          alt="" 
          class="szoveg1kep"
          />
          </div>
          <div class="szoveg1bal">
            <div>
              <h2 data-w-id="95f0aad7-503e-fc74-06c0-0f11e1120ee9" ref={(el) => elementsToObserve.current.push(el)} class="szoveg1heading">Dr. németh katalin</h2>
              <div class="text-block-17">Fogorvos és fogszabályozó szakorvos</div>
            </div>
            <p class="paragraph-2"><strong class="bold-text-2">Dr. Németh Katalin fogorvos és fogszabályozó szakorvos vagyok. 2005-ben végeztem a Pécsi Orvostudományi Egyetem Fogorvosi Szakán.Fogszabályozó szakorvosi vizsgámat 2008-ban szereztem meg Budapesten. <br/><br/>Az elmúlt időszakban Kaposváron, a Herczeg Fogászaton dolgoztam közreműködőként. Jelenleg Nagykanizsán rendelek magánvállalkozóként, és részt veszek a városi TB által támogatott fogszabályozásban is.Szeretek az embereken segíteni, különösen a gyermekeken.</strong></p>
          </div>
        </div>
        <div class="szoveg2">
          <div class="szoveg2bal"><img src="images/IMG_4289-1.jpg" loading="lazy" sizes="(max-width: 767px) 293.671875px, 100vw" srcset="images/IMG_4289-1-p-500.jpg 500w, images/IMG_4289-1-p-800.jpg 800w, images/IMG_4289-1.jpg 1080w" alt="" class="image-6"/></div>
          <div class="szoveg2jobb">
            <div>
              <h2 data-w-id="95f0aad7-503e-fc74-06c0-0f11e1120efa" ref={(el) => elementsToObserve.current.push(el)} class="szoveg2heading">Salamon bernadett</h2>
              <div class="text-block-38">Fogászati asszisztens</div>
            </div>
            <p class="paragraph-4"><strong class="bold-text-2">Salamon Bernadett a nevem, fogászati asszisztensként végeztem a Pécsi Szociális és Egészségügyi Szakképző Iskolában. <br/><br/>Először Dr. Rejtő Attila privát rendelőjében dolgoztam asszisztensként. Főként szájsebészettel és implantológiával foglalkoztunk.<br/><br/>2007 óta dolgozom a nagykanizsai TB által támogatott fogszabályozáson, és 2009 óta Dr. Németh Katalin magánrendelésén is.</strong></p>
          </div>
        </div>
        <div class="elotteutanawrap" >
          <h1 class="elotteutanaheading" ref={(el) => elementsToObserve.current.push(el)}>Előtte - <span class="text-span">utána</span></h1>
          <div class="elotteutana">
            <div class="elotteutanabal"><img src="images/IMG_4257.JPG" loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 74vw, 100vw" srcset="images/IMG_4257-p-500.jpg 500w, images/IMG_4257-p-800.jpg 800w, images/IMG_4257-p-1080.jpg 1080w, images/IMG_4257-p-1600.jpg 1600w, images/IMG_4257.JPG 1914w" alt="" class="image-16"/></div>
            <div class="elotteutanajobb"><img src="images/IMG_4256.JPG" loading="lazy" sizes="(max-width: 479px) 94vw, (max-width: 767px) 74vw, 100vw" srcset="images/IMG_4256-p-500.jpg 500w, images/IMG_4256-p-800.jpg 800w, images/IMG_4256-p-1080.jpg 1080w, images/IMG_4256-p-1600.jpg 1600w, images/IMG_4256-p-2000.jpg 2000w, images/IMG_4256.JPG 2036w" alt="" class="szoveg3kep"/></div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Section2Rolunk;