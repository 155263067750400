import React, { useEffect, useRef } from "react";

const ContentSzolg = () => {
    const elementsToObserve = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          //entry.target.classList.remove("in-view");
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    elementsToObserve.current.forEach((el) => {
      if (el) observer.observe(el);
    });

    return () => {
      elementsToObserve.current.forEach((el) => {
        if (el) observer.unobserve(el);
      });
    };
  }, []);


  return (
    <div>
        <div class="contentholder-2">
      <div class="szolgaltatasheadingcontent">
        <h1 class="szolgaltatasokmainheading">Szolgáltásaink</h1>
        <div class="szolgaltatasainkmaintext">Mi rendbe tesszük a mosolyod<br/>a személyre szabott szolgáltatásainkkal!</div>
      </div>
      <div class="szolgaltatasokwrap">
      <div class="szolgaltatasoksection4">
          <div data-w-id="cfc9019c-ff3c-5288-dd17-5e9b5c5edaa9" ref={(el) => elementsToObserve.current.push(el)} class="fogszabalyzasheading">
            <div class="fogszabalyzaskorvonal">
              <h1 class="heading-28">Fogszabályozás</h1>
            </div>
          </div>
          <div class="fogszabalyzassection1">
            <div class="rogzitettkeszulekfem">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">rögzített készülék fém brackettel (1 fogívre)</h1>
                <div class="szolgaltatasara">
                  <p class="rogzitettfemar">130.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos megtisztítja a fogakat, majd ragasztóval fém bracketeket helyez rájuk. Ezután egy drótot fűz át a bracketeken, és gumigyűrűkkel vagy fémligatúrákkal rögzíti. A drót folyamatosan nyomást gyakorol a fogakra, így azok fokozatosan a kívánt helyzetbe mozdulnak.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="rogzitettkeszulekkeramia">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">rögzített készülék kerámia brackettel (1 fogívre)</h1>
                <p class="rogzitettkeramiaar">180.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos megtisztítja a fogakat, majd fogászati ragasztóval kerámia bracketeket helyez rájuk. Ezután egy drótot fűz át a bracketeken, és gumigyűrűkkel vagy fém ligatúrákkal rögzíti. A drót nyomást gyakorol a fogakra, így azok fokozatosan a kívánt helyzetbe mozdulnak.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection2">
            <div class="onligirozofembracket">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">önligírozó fém bracketsor</h1>
                <div class="szolgaltatasara">
                  <p class="onligirozofemar">200.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos megtisztítja a fogakat, majd ragasztóval önligírozó fém bracketeket helyez rájuk. Ezek a bracketek beépített zárakkal rendelkeznek, így nincs szükség gumigyűrűkre. Ezután egy drótot fűz át a bracketeken, amely nyomást gyakorol a fogakra, segítve azok igazítását.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="onligirozokeramia">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">önligírozó kerámia bracketsor</h1>
                <p class="onligirozokeramiaar">250.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos megtisztítja a fogakat, majd ragasztóval önligírozó kerámia bracketeket helyez rájuk. Ezek a bracketek beépített zárakkal rendelkeznek, így nincs szükség gumigyűrűkre. Ezután egy drótot fűz át a bracketeken, amely nyomást gyakorol a fogakra, segítve azok igazítását.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection3">
            <div class="kezelesitervkeszites">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">kezelési terv készítés + lenyomatvétel (1 fogív-2 fogív)</h1>
                <div class="szolgaltatasara">
                  <p class="kezelesitervar">5-10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos először vizsgálatot végez és röntgenfelvételeket készít, hogy elkészítse a kezelési tervet. Ezután lenyomatot vesz az egyik vagy mindkét fogívről. A lenyomatokat gipsz vagy digitális technika segítségével használják a fogszabályozó készülékek pontos elkészítéséhez.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="kivehetokeszulekek">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">kivehető készülékek</h1>
                <p class="kivehetokeszulekar">50-70.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos kivehető készülékeket, például fogszabályzókat vagy fogpótlásokat alkalmaz, hogy korrigálja a fogak helyzetét, javítsa a harapást vagy pótolja a hiányzó fogakat. Ezeket a páciensek otthon is eltávolíthatják tisztítás vagy étkezés közben, biztosítva a kényelmet és a higiéniát.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection4">
            <div class="kivehetokeszulekalkalmankent">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">kivehető készülék aktiválása alkalmanként</h1>
                <div class="szolgaltatasara">
                  <p class="kivehetoaktivalasar">5.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos kivehető készülék aktiválást végez, beállítja vagy finomhangolja a készüléket, hogy továbbra is megfelelően korrigálja a fogakat vagy az állkapocs helyzetét. Ez rendszerint rövid ellenőrzés és állítás, amit a páciensnek otthon viselnie kell a hatékony kezelés érdekében.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="rogzitettkeszulek1fogiv">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">rögzített készülék aktiválása 1 fogíven</h1>
                <p class="rogzitettaktivalasar">8.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos rögzített készülék aktiválását végzi egy fogíven, beállítja a fogszabályzó drótokat vagy gumiszalagokat, hogy megfelelő nyomást gyakoroljanak a fogakra. Ez segíti a fogak fokozatos elmozdulását a kívánt helyzetbe, és rendszeres ellenőrzést igényel a hatékony kezelés érdekében.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection5">
            <div class="rogzitettkeszulekaktivalas">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">rögzített készülék aktiválása 2 fogíven</h1>
                <div class="szolgaltatasara">
                  <p class="rogzitettaktivalas2ivar">10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos rögzített készülék aktiválását végzi két fogíven, beállítja a felső és alsó fogszabályzó drótokat vagy gumiszalagokat, hogy megfelelő nyomást gyakoroljanak a fogakra. Ez segíti a fogak fokozatos elmozdulását és rendszeres ellenőrzést igényel.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="fogszabalyzaseltavolitas">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">fogszabályzó eltávolítás (1 fogív)</h1>
                <p class="fogszabalyzoeltavoltasar">15.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos eltávolítja a fogszabályzót egy fogívről, lecsatolja a braketeket és drótokat, majd megtisztítja a fogakat a ragasztó maradványaitól. A kezelés végén retenciós készüléket ad, hogy fenntartsa a fogak új helyzetét és megelőzze a visszarendeződést.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection6">
            <div class="kivehetoretencios">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">kivehető retenciós lemez (típusfüggő)</h1>
                <div class="szolgaltatasara">
                  <p class="kivehetoretenciosar">13.000-20.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos kivehető retenciós lemezt készít, lenyomatot vesz a fogakról és egy egyedi készüléket készít, amely segít megtartani a fogak új helyzetét a fogszabályzó eltávolítása után. A páciensnek rendszeresen viselnie kell, típusától függően éjjel vagy nappal.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="rogzitettretainer">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">rögzített retainer ragasztás</h1>
                <p class="rogzitettretainerar">18.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor a fogorvos rögzített retainert ragaszt, egy vékony fémhuzalt rögzít a fogak belső oldalára speciális ragasztóval. Ez a huzal segít megtartani a fogak új helyzetét, megakadályozva azok visszarendeződését. Az eljárás gyors és fájdalommentes.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection7">
            <div class="potalkatresz">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">pót alkatrész ragasztása</h1>
                <div class="szolgaltatasara">
                  <p class="potalkatreszar">5.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos pótalkatrészeket, például koronákat vagy hidakat ragaszt a fogra, ha az megsérült vagy hiányzik. Először előkészíti és megtisztítja a fogat, majd speciális ragasztóval rögzíti az új alkatrészt. Ez biztosítja a stabil és tartós kapcsolatot, helyreállítva a fog funkcióját és megjelenését.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="happysmileterv">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">HappySmile láthatatlan fogszabályozás kezelési terv</h1>
                <p class="happysmiletervar">30.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A HappySmile láthatatlan fogszabályozás kezelési terv során digitális lenyomatot készítenek a fogakról, majd 3D-s tervet állítanak össze, amely megmutatja a kezelés várható eredményét. Ez alapján egyedi, áttetsző sínpárokat gyártanak. A páciens ezeket a síneket meghatározott ideig viseli, hogy fokozatosan igazítsák a fogakat.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection8">
            <div class="happysmilefotok">
              <div class="happysmilelathatatlan">
                <h1 class="fenyrekotoheading">HappySmile láthatatlan fogszabályozáshoz foglenyomat és fotók készítése</h1>
                <div class="szolgaltatasara">
                  <p class="happysmilefotokar">5.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A HappySmile láthatatlan fogszabályozás első lépése a foglenyomat és fotók készítése. A fogorvos digitális lenyomatot vesz a fogakról és fotókat készít a páciens mosolyáról. Ezeket az adatokat egy 3D-s terv készítéséhez használják, amely alapján elkészítik az egyedi, áttetsző sínpárokat a kezeléshez.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="happysmilesinek">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">HappySmile sínek darabja</h1>
                <p class="happysmilesinar">30.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A HappySmile láthatatlan fogszabályozó sínek egyedi darabjai áttetsző műanyagból készülnek, amelyek pontosan illeszkednek a páciens fogaira. Ezek a sínek kivehetők, és két-három hetente cserélni kell őket, hogy fokozatosan mozgassák a fogakat a kívánt helyzetbe, biztosítva a kényelmes és hatékony kezelést.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="fogszabalyzassection9">
            <div class="eroszakmentesfogszabalyzas">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">erőszakmentes fogszabályozás konzultáció és készülékek (kollégánál történik)</h1>
                <div class="szolgaltatasara">
                  <p class="eroszakmentesar">600 Euro</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Az erőszakmentes fogszabályozás konzultáció során a kolléga áttekinti a páciens fogazati állapotát, megbeszéli a kezelési lehetőségeket és célokat. Ezután készülékeket választanak ki, melyek gyengéd erőkkel igazítják a fogakat. A konzultáció célja a személyre szabott, kényelmes és hatékony kezelés biztosítása.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
        </div>
        <div class="szolgaltatasoksection1">
          <div data-w-id="b8946ccf-3d06-3a0d-8f6b-35254ebcf54a" ref={(el) => elementsToObserve.current.push(el)} class="konzervaloheading">
            <div class="konzervaloheadingkorvonal">
              <h3 class="heading-25">Konzerváló fogászat</h3>
            </div>
          </div>
          <div class="konzervalofogsection1">
            <div class="fenyrekototomes">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">Fényre kötő esztétikus tömés</h1>
                <div class="szolgaltatasara">
                  <p class="fenyrekotoar">20.000-30.000 Ft.</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Fényre kötő esztétikus tömés kezelés során egy speciális anyagot használunk, amely a fog színéhez igazodik. Az anyagot rétegekben viszi fel a szuvas vagy sérült fogra, majd egy fényforrással megvilágítva megkeményíti, így helyreállítva a fog eredeti formáját és funkcióját.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="gyokerkezeles">
              <div class="div-block-34">
                <h1 class="gyokerkezelesheading">gyökérkezelés</h1>
                <p class="gyokerkezelesar">5.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Gyökérkezelés során a fogorvos eltávolítja a fertőzött vagy sérült fogbelet. Ezt követően megtisztítja és fertőtleníti a gyökércsatornákat, majd egy speciális tömőanyaggal tölti fel azokat. A kezelést végül egy koronával vagy töméssel zárja le, helyreállítva a fog funkcióját és esztétikáját.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="konzervalofogsection2">
            <div class="gyokertomescsatornankent">
              <div class="nevardiv">
                <h1 class="gyokertomeshead">gyökértömés csatornánként</h1>
                <div class="szolgaltatasara">
                  <p class="gyokertomesar">10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Gyökértömés során a fogorvos csatornánként dolgozik. Először megtisztítja és fertőtleníti a gyökércsatornákat, majd rétegezve egy speciális tömőanyaggal tölti fel őket. Minden csatornát külön kezel, így biztosítva a fertőzésmentes, tartós tömést és a fog stabilitását.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="ideiglenestom">
              <div class="nevardiv">
                <h1 class="ideiglenestomesheading">ideiglenes tömés</h1>
                <p class="ideiglenestomesar">7.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Ideiglenes tömés során a fogorvos eltávolítja a szuvas részeket, majd egy átmeneti anyaggal tölti fel a fogat. Ez az anyag megvédi a fogat és csökkenti a fájdalmat, amíg a végleges kezelés meg nem történik. Az ideiglenes tömés később könnyen eltávolítható a végleges töméshez.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="konzervalofogsection3">
            <div class="foghuzas">
              <div class="nevardiv">
                <h1 class="foghuzasheading">foghúzás</h1>
                <div class="szolgaltatasara">
                  <p class="foghuzasar">8.000-10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Fog húzása során a fogorvos helyi érzéstelenítést alkalmaz, majd speciális eszközökkel meglazítja és eltávolítja a fogat. A kihúzás után a sebet megtisztítja és szükség esetén összevarrja. A páciens utókezelési tanácsokat kap a gyorsabb gyógyulás érdekében.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="gyogyszerrendeles">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">gyógyszerrendelés</h1>
                <p class="gyogyszerrendelesar">3.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Amikor gyógyszert kell rendelni a fogorvoson keresztül, a fogorvos megvizsgálja a problémát, majd receptet ír fel a szükséges gyógyszerre, például antibiotikumra vagy fájdalomcsillapítóra. A páciens ezt a receptet a gyógyszertárban válthatja ki, és a fogorvos utasításai szerint szedi be.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="konzervalofogsection4">
            <div class="ideiglenesgyokertomes">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">ideiglenes gyökértömés csatornánként</h1>
                <div class="szolgaltatasara">
                  <p class="ideiglenesgyokertomesar">8-10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Ideiglenes gyökértömés során a fogorvos csatornánként megtisztítja és fertőtleníti a gyökércsatornákat, majd ideiglenes tömőanyagot helyez bele. Ez az anyag megvédi a csatornákat és lehetővé teszi a gyulladás csökkenését, amíg a végleges tömés el nem készül.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="fedotomesgyokerkezeles">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">fedőtömés gyökérkezelés után</h1>
                <p class="fedotomesar">12.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A fogorvos először megtisztítja a fogat, majd a gyökérkezelés során kitöltött gyökércsatornákra egy végleges fedőtömést helyez. Ez a tömés lezárja a fogat, megvédi a baktériumoktól, és helyreállítja a fog funkcióját és szerkezetét.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
        </div>
        <div class="szolgaltatasoksection2">
          <div data-w-id="988d56f0-8327-0906-6384-82e0c638aa16" ref={(el) => elementsToObserve.current.push(el)} class="paradontologiaheading">
            <div class="paradontologiakorvonal">
              <h1 class="heading-26">Paradontológia</h1>
            </div>
          </div>
          <div class="paradontologiasection1">
            <div class="fogkoeltavolitas">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">fogkő eltávolítás és polírozás állcsontonként</h1>
                <div class="szolgaltatasara">
                  <p class="fogkoar">10.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Fogkő eltávolítás és polírozás során a fogorvos ultrahangos eszközzel vagy kézi műszerekkel tisztítja meg az állcsont fogait a fogkőtől. A polírozás során speciális pasztát használ, amely simává teszi a fogfelszínt, megelőzve az új fogkő kialakulását és javítva a szájhigiéniát.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="nyalkahartyabetegseg">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">nyálkahártya betegség kezelése</h1>
                <p class="nyalkahartyaar">2.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Nyálkahártya betegség kezelésekor a fogorvos azonosítja az okot, például fertőzést vagy sérülést. Kezelésként gyógyszereket, például antibiotikumot vagy fertőtlenítőt alkalmaz, illetve tisztítja és védi a sérült területet. Célja a gyógyulás elősegítése és a fájdalom csökkentése.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="paradontologiasection2">
            <div class="fognyakierzekenyseg">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">fognyaki érzékenység fluoridos kezelése</h1>
                <p class="fognyakiar">2.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Fognyaki érzékenység fluoridos kezelésekor a fogorvos magas koncentrációjú fluoridos gélt vagy lakkot alkalmaz az érintett területre. Ez erősíti a fogzománcot, csökkenti az érzékenységet, és védelmet nyújt a fogszuvasodás ellen. A kezelés gyors és fájdalommentes.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
        </div>
        <div class="szolgaltatasoksection3">
          <div data-w-id="13413c42-3568-1e9a-6a75-a8806e255441" ref={(el) => elementsToObserve.current.push(el)} class="gyermekfogaszatheading">
            <div class="gyermekfogaszatkorvonal">
              <h1 class="heading-27">Gyermekfogászat</h1>
            </div>
          </div>
          <div class="gyermekfog-szatsection1">
            <div class="barazdazas">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">barázdazárás foganként</h1>
                <div class="szolgaltatasara">
                  <p class="barazdazasar">7.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Barázdazáráskor a fogorvos tisztítja a fog barázdáit, majd speciális, folyékony tömőanyagot visz fel rájuk. Ez megkeményedik, lezárva a barázdákat, így megelőzve a szuvasodást. A kezelés gyors, fájdalommentes és főleg gyermekeknél gyakori.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="elhaltfogkezelses">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">elhalt tejfog kezelése</h1>
                <p class="elhalttejar">3.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Elhalt tejfog kezelésekor a fogorvos eltávolítja a fog belsejében lévő elhalt szöveteket, majd fertőtleníti a területet. Ezután tömőanyaggal tölti fel a fogat, hogy megakadályozza a fertőzések terjedését és fenntartsa a fog funkcióját a maradó fog előbújásáig.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
          <div class="gyermekfog-szatsection2">
            <div class="tejfogeltavolitas">
              <div class="nevardiv">
                <h1 class="fenyrekotoheading">tejfog eltávolítás</h1>
                <div class="szolgaltatasara">
                  <p class="tejfoghuzasar">5.000 Ft</p>
                </div>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">Tejfog eltávolításakor a fogorvos helyi érzéstelenítést alkalmaz, majd speciális fogóval óvatosan kiveszi a fogat. Az eltávolítás után tanácsokat ad a seb gyógyítására és a fájdalom csillapítására. Az eljárás gyors és általában fájdalommentes, így a gyerekek könnyen átvészelik.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
            <div class="tejfogtrepanalas">
              <div class="nevardiv">
                <h1 class="gyokerkezelesheading">tejfog trepanálás</h1>
                <p class="fenyrekotoar">4.000 Ft</p>
              </div>
              <div class="szolgaltatasleiras">
                <p class="mireszamithatsz">Mire számíthatsz?</p>
                <p class="leiras">A tejfog trepanálása során a fogorvos helyi érzéstelenítést alkalmaz, majd egy kis nyílást fúr a fog koronájába, hogy hozzáférjen a gyulladt vagy fertőzött pulpához. Ezt követően eltávolítja a pulpát, fertőtleníti a területet, majd ideiglenes töméssel zárja le a fogat.</p>
              </div>
              <div class="mennyiideigdiv">
                <p class="mennyiido">Mennyi időt vesz igénybe?</p>
                <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
                <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
              </div>
            </div>
          </div>
        </div>
        
        <div class="szolgaltatasoksection5">
          <div data-w-id="afff71cf-32a0-c299-e1f8-4de562fa821c" ref={(el) => elementsToObserve.current.push(el)} class="fogekszerheading">
            <div class="fogekszerragasztaskorvonal">
              <h1 class="heading-29">Fogékszer ragasztás</h1>
            </div>
          </div>
          <div class="fogekszerragasztas">
            <div class="nevardiv">
              <h1 class="gyokerkezelesheading">Fogékszer ragasztás</h1>
              <p class="fogekszerar">15.000 Ft<br/></p>
            </div>
            <div class="szolgaltatasleiras">
              <p class="mireszamithatsz">Mire számíthatsz?</p>
              <p class="leiras">A fogékszer ragasztás során a fogorvos először megtisztítja és szárítja a kiválasztott fog felszínét. Ezután egy speciális ragasztóval rögzíti a fogékszert a fogra. Az UV-fény segítségével megköti a ragasztót, így az ékszer biztonságosan és tartósan a helyén marad.</p>
            </div>
            <div class="mennyiideigdiv">
              <p class="mennyiido">Mennyi időt vesz igénybe?</p>
              <p class="konzultacio">Egyáltalán nem hosszadalmas. Az időtartam egyénenként változhat, bővebb információ kunzultáció után.</p>
              <a href="/foglalas" class="idopontgomb w-button">Időpont kérése</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default ContentSzolg;