import axios from 'axios';
import React, { useEffect, useState } from 'react';

const AdminSettings = () => {
    const [settings, setSettings] = useState({
        price1: 0,
        price2: 0,
        price3: 0,
        price4: 0,
        price5: 0,
        price6: 0,
        canReserve: 0,
        canPay: 0,
        holiday: 0,
        hStart: "",
        hEnd: ""
    });

    const fetchSettings = async () => {
        axios.get(`https://katadent.hu:41806/admin/getAdminSettings`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
            setSettings(response.data)
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    };

    const updateSettings = async () => {
        axios.get(`https://katadent.hu:41806/admin/setAdminSettings?price1=${settings.price1}&price2=${settings.price2}&price3=${settings.price3}&price4=${settings.price4}&price5=${settings.price5}&price6=${settings.price6}&canReserve=${settings.canReserve}&canPay=${settings.canPay}&holiday=${settings.holiday}&hStart=${settings.hStart}&hEnd=${settings.hEnd}`, {
            headers: {
              Authorization: `Bearer ${"KataDentAuthToken201829014901201301sad31231dkj0hjk13lkd012sd0120"}`,
              'Content-Type': 'application/json' // Ensure the correct Content-Type is set
            }
          })
          .then(response => {
            console.log('Response:', response);
          })
          .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error('Payment Error:', error.response.data);
                console.error('Status:', error.response.status);
                console.error('Headers:', error.response.headers);
            } else if (error.request) {
                // The request was made but no response was received
                console.error('Payment Error:', error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error('Error', error.message);
            }
          });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Check if the field is hStart or hEnd, and keep it as a string
        if (name === "hStart" || name === "hEnd") {
            setSettings({
                ...settings,
                [name]: value
            });
        } else {
            // For other fields, parse the value as an integer
            setSettings({
                ...settings,
                [name]: parseInt(value)
            });
        }
    };

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        setSettings({
            ...settings,
            [name]: checked ? 1 : 0
        });
    };

    useEffect(() => {
        fetchSettings();
    }, []);

    return (
        <div className="admin-settings">
            <h2>Admin Beállítások</h2>
            <form onSubmit={(e) => { e.preventDefault(); updateSettings(); }}>
                {Object.keys(settings).filter(key => !key.startsWith('can','hol')).map((key) => (
                    (key !== "id" && key !== "holiday" && key !== "hStart" && key !== "hEnd" && <div key={key} className="settings-group">
                        <label htmlFor={key}>{key === "price1" ? "Konzultáció (10p) Ára" : 
                                              key === "price2" ? "Fogszabályozás konzultáció (10p) Ára" : 
                                              key === "price3" ? "Fogkő leszedés (20p) Ára" :  
                                              key === "price4" ? "Gyógyszer rendelés (10p) Ára" :
                                              key === "price5" ? "Tejfog vizsgálat (10p) Ára" : 
                                              key === "price6" ? "Fogékszer ragasztás (10p) Ára" : 
                                              key === "Can Reserve" ? "Foglalás az oldalon (ki/be)" : 
                                              key === "Can Pay" ? "Fizetés az oldalon (ki/be)" : ""                   
                        }
                        </label>
                        <input
                            type="number"
                            id={key}
                            name={key === "price1" ? "Konzultáció (10p) Ára" : ""}
                            value={settings[key]}
                            onChange={handleInputChange}
                            className="settings-input"
                        />
                    </div>)
                ))}
                <div className="settings-group toggle-group">
                    <label htmlFor="canReserve">Foglalás az oldalon (ki/be)</label>
                    <input
                        type="checkbox"
                        id="canReserve"
                        name="canReserve"
                        checked={settings.canReserve === 1}
                        onChange={handleToggleChange}
                        className="settings-toggle"
                    />
                </div>
                <div className="settings-group toggle-group">
                    <label htmlFor="canPay">Fizetés az oldalon (ki/be)</label>
                    <input
                        type="checkbox"
                        id="canPay"
                        name="canPay"
                        checked={settings.canPay === 1}
                        onChange={handleToggleChange}
                        className="settings-toggle"
                    />
                </div>
                <div className="settings-group toggle-group">
                    <label htmlFor="holiday">Szabadság (ki/be)</label>
                    <input
                        type="checkbox"
                        id="holiday"
                        name="holiday"
                        checked={settings.holiday === 1}
                        onChange={handleToggleChange}
                        className="settings-toggle"
                    />
                </div>
                <div className="settings-group toggle-group">
                    <label htmlFor="hStart">Szabadság kezdete</label>
                    <input
                            type="text"
                            id="hStart"
                            name="hStart"
                            value={settings.hStart}
                            onChange={handleInputChange}
                            className="settings-input"
                        />
                </div>
                <div className="settings-group toggle-group">
                    <label htmlFor="hEnd">Szabadság Vége</label>
                    <input
                            type="text"
                            id="hEnd"
                            name="hEnd"
                            value={settings.hEnd}
                            onChange={handleInputChange}
                            className="settings-input"
                        />
                </div>
                <button type="submit" className="settings-submit-button">Beállítások mentése</button>
            </form>
        </div>
    );
};

export default AdminSettings;