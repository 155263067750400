import React, { useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import { useBase } from "../../contexts/BaseContext";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Nav from "./Nav";
import FooterComp from "./Footer";

//const pages = [<Page1 setServiceSelected={() => {}} />, <Page2 setDateSelected={() => {}} />, <Page3 />, <Page4 />];

const LandingPagePc = () => {
  const {fetchSettings,settings,pages, setSelectedTime,firstOpen,setFirstOpen,nextPage,currentPage, setCurrentPage,isServiceSelected, setIsServiceSelected,isDateSelected, setIsDateSelected ,doneRes,setDoneRes} = useBase();
  useEffect(() => {
    fetchSettings();
}, []);
  
  useEffect(() => {
    if (doneRes === "SUCCESS-NOPAY") {
      setDoneRes("");
      toast.success('Sikeres Időpont Foglalás!');
    } else if (doneRes === "SUCCESS-PAY") {
      setDoneRes("");
      toast.success('Sikeres Fizetés!');
      toast.success('Sikeres Időpont Foglalás!');
    } else if (doneRes === "FAIL-NOPAY") {
      setDoneRes("");
      toast.error('Sikertelen Időpont Foglalás!');
    } else if (doneRes === "FAIL-PAY") {
      setDoneRes("");
      toast.error('Sikertelen Fizetés!');
      toast.error('Sikertelen Időpont Foglalás!');
    }
}, [doneRes]);

  const setPage = (pageIndex) => {
    if (pageIndex <= currentPage) {
      setCurrentPage(pageIndex);
      setFirstOpen(true);
    }
  };

  const updatePage = () => {
    return pages.map((page, index) => {
      if (index === 0) {
        return React.cloneElement(page, { setServiceSelected: setIsServiceSelected });
      } else if (index === 1) {
        return React.cloneElement(page, { setDateSelected: setIsDateSelected });
      } else if (index === 2) {
        return React.cloneElement(page, { setTimeSelected: setSelectedTime });
      } else {
        return page;
      }
    })[currentPage];
  };

  return (
    <>
    <Nav/>
    {settings.holiday &&
      <div className="unavailable-container">
          <h2>Szabadságon vagyok!</h2>
          <p> {settings.hStart} - {settings.hEnd}</p>
          <p>Az online foglalás ezen az intervallumon kívűl továbbra is lehetséges!</p>
        </div>
    }
    <div className="landing-page">
      <ProgressBar currentPage={currentPage} setPage={setPage} totalPages={pages.length} />
      <div className="page-container">
        {updatePage()}
        {settings.canReserve && currentPage < pages.length - 1 && (
          <button
            className="next-button"
            onClick={nextPage}
            disabled={currentPage === 0 ? !isServiceSelected : currentPage === 1 ? !isDateSelected : false}
            style={{display:currentPage === 2 ? "none" : "inline"}}
          >
            {currentPage >= pages.length - 1 ? "Foglalás" : "Következő"}
          </button>
        )}
      </div>
    </div>
    <FooterComp/>
    <ToastContainer position="bottom-left"/>
    </>
  );
};

export default LandingPagePc;